import React from 'react';
import { Link, graphql, useStaticQuery } from 'gatsby';

import Layout from '../components/layout';
import Head from '../components/head';

const BlogPage = () => {
  const data = useStaticQuery(graphql`
    query{
      allMdx(
        sort: { fields: [frontmatter___date], order: DESC }
        limit: 1000
      ) {
        nodes {
          body
          frontmatter {
            title
            date
            slug
          }
          excerpt
        }
      }
    }
  `);

  const postsIndex = data.allMdx.nodes.map((post, idx) => {
    const { frontmatter: { title, slug, date }, excerpt} = post;
    const dateOptions = { year: 'numeric', month: 'numeric', day: 'numeric' };
    const locale = "en-US";
    const formattedDate = new Date(date).toLocaleDateString(locale, dateOptions).replace(/\//g, '-');

    return (
      < li className="list-none border-t border-gray-500" key={idx} >
        <article className="pt-4">
          <time className="text-base text-gray-500 font-semibold" dateTime={date}>{formattedDate}</time>
          <Link to={slug}>
            <h2 className="pb-3 underline text-xl hover:text-teal-800 font-bold">{title}</h2>
          </Link>
          <p className="mb-6">{excerpt}</p>
        </article>
      </li >
    )
  });

  return (
    <Layout>
      <Head title="Blog" />
      <div className="mb-6">
        <h1 className="text-3xl font-bold">Blog</h1>
        <p className="text-base text-gray-500 font-semibold">A life in development.</p>
        <ol className="mt-6">
          {postsIndex}
        </ol>
      </div>
    </Layout>
  )
}

export default BlogPage;